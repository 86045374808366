import React, { Component } from 'react';

import ContactLink from '../ContactLink';

import roboticsImg from './robotics.jpg';
import softwarePic from './UseYourFavoriteSoftware.svg';
import valueProp3Img from './sparks.jpg';

import './ValuePropositions.css';

class ValuePropositions extends Component {
    render() {
        return (
            <section className="content">
                <div className="value-prop-grid">
                    <div className="vp1 image">
                        <img src={roboticsImg} alt="An engineer working on a robot with laser cut parts." />
                    </div>
                    <div className="vp1 text">
                        <h2>From a design to parts in hand - fast.</h2>
                        <p>
                            Shortly after ordering your laser cut parts, our team will get to work reviewing your design.  If everything looks ok, we'll get the parts cut and
                                shipped to you in <em>under a week</em>.
                            </p>
                        <a href="#main">Order Now</a>
                    </div>
                    <div className="vp2 image">
                        <img src={softwarePic} alt="Illustration of someone using their favorite laser cutting software." />
                    </div>
                    <div className="vp2 text">
                        <h2>Use <em>your</em> favorite software, not ours.</h2>
                        <p>
                            There's no need to risk damaging your design with risky file conversions, or being forced to use
                            someone else's tool.  Chances are, our advanced online quoting system can get you an immediate
                            quote without the hassle.
                            </p>
                        <a href="#main">Order Now</a>
                    </div>
                    <div className="vp3 image">
                        <img src={valueProp3Img} alt="Sparks flying away from a material that's being laser cut." />
                    </div>
                    <div className="vp3 text">
                        <h2>Choose from many materials.</h2>
                        <p>
                            Our software makes it easy to order your custom parts, regardless of whether you're ordering an acrylic
                            plate for your robotics hobby or if you're an Etsy seller making wooden earrings from baltic birch.  
                            
                            Need something special?  <ContactLink>Ask us!</ContactLink>
                        </p>
                        <a href="#main">Order Now</a>
                    </div>
                </div>
            </section>
        );
    }
}

export default ValuePropositions;