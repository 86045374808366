import { createSelector } from 'reselect';
import {round} from '../lib/format';

const getQuote = (state) => {
    return state.order.quote;
}
const getQuantity = (state) => {
    return state.order.quantity;
}

export const getVolumePrice = createSelector(
    [getQuote, getQuantity],
    (quote, quantity) => {
        if (quote && quote.quotedVolumePrices) {
            return quote.quotedVolumePrices.find(vp => vp.minimumQuantity <= quantity && vp.maximumQuantity >= quantity);
        }
        return null;
    }
);


export const getSubtotal = createSelector(
    [getVolumePrice, getQuantity],
    (vp, quantity) => {
        if (vp && quantity) {
            const subtotal = vp.pricePerUnit * quantity;
            return round(subtotal, 2);
        }
        return null;
    }
);