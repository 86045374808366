import React, { Component, Fragment } from 'react';

import Button from '@material-ui/core/Button';

import Confetti from 'react-dom-confetti';

class SubscribedThankYou extends Component {
    constructor() {
        super();
        this.state = {confetti: false};
    }
    componentDidMount() {
        this.setState({confetti: true});
    }
    dismissThanks = () => {
        this.setState({confetti: false});
        this.props.dismissThanks();
    }
    render() {
        const config = {
            angle: 65,
            spread: 75,
            startVelocity: 200,
            elementCount: 200,
            decay: 0.4
        };
        return (
            <Fragment>
                <h4>Thanks for Subscribing!</h4>
                <p>Keep an eye on your email to see if you win this month's Arduino Uno.</p>
                <Confetti active={this.state.confetti} config={ config }/>
                <Button 
                    id='thanks-for-subscribing'
                    onClick={this.dismissThanks}>
                    THANKS FOR SUBSCRIBING
                </Button>
            </Fragment>
        );
    }
}

export default SubscribedThankYou;