import React, { Component } from 'react'

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default class QuestionAnswer extends Component {
    render() {
        const { question, children } = this.props;
        return (
            <ExpansionPanel className="question">
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <div style={{fontWeight: 'bold'}}>{question}</div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <div>
                        {children}
                    </div>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}
