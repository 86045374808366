import React, {Component} from 'react';
import ReactMarkdown from 'react-markdown';

import ImageSection from '../ImageSection';
import craftingImage from '../home/crafting.jpg';

class PostPage extends Component {
    componentDidMount() {
        const slug = this.props.match.params.slug;
        this.props.fetchBlogPost(slug);
    }
    render() {
        const {title, date, content} = this.props;
        if (!title) {
            return <div>Loading...</div>;
        }
        const dateString = date.toLocaleDateString();
        return (
            <main>
                <ImageSection image={craftingImage} />
                <div className="main-content">
                    <h1 className="post-title">{title}</h1> 
                    <span className="post-date">{dateString}</span>
                    <div className="post-body">
                        <ReactMarkdown source={content} />
                    </div>
                </div>
            </main>
        );
    }
}

export default PostPage;