import React, { Component, Fragment } from 'react';
import Icon from '@material-ui/core/Icon';
import Slide from 'react-reveal/Slide';
import Fade from 'react-reveal/Fade';

import './HowItWorksSection.css';

class HowItWorksSection extends Component {
    render() {
        return (
            <section className="content how-it-works">
                <div>
                    <h2>How it Works</h2>
                    <ul className="steps">
                        <li>
                            <Slide left duration={2000}>
                                <Fragment>
                                    <Icon className="fa fa-pen-nib" color="primary" />
                                    <h3>Design</h3>
                                    <p>Design your part in your favorite software and export it to DXF.</p>
                                </Fragment>
                            </Slide>
                        </li>
                        <li>
                            <Fade bottom duration={1500} delay={500}>
                                <Fragment>
                                    <Icon className="fa fa-cloud-upload-alt" color="primary" />
                                    <h3>Upload</h3>
                                    <p>Upload your file, pick your material, and get an instant quote.</p>
                                </Fragment>
                            </Fade>
                        </li>
                        <li>
                            <Slide right duration={2000}>
                                <Fragment>
                                    <Icon className="fa fa-puzzle-piece" color="primary" />
                                    <h3>Create</h3>
                                    <p>Once you've placed your order, we'll get to work on your parts.</p>
                                </Fragment>
                            </Slide>
                        </li>
                    </ul>
                </div>
            </section>
        );
    }
}

export default HowItWorksSection;