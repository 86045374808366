import React, { Component } from 'react';

import QuantitySelector from './QuantitySelector';
import PricingOptions from './PricingOptions';

class QuantityOptions extends Component {
    componentDidMount() {
        const {fetchQuote, fileSubmissionId, stockMaterialId} = this.props;
        fetchQuote(fileSubmissionId, stockMaterialId);
    }
    render() {
        return (
            <div>
                <QuantitySelector 
                    onSelected={this.props.onQuantityChanged} 
                    quantity={this.props.quantity} 
                    />
                <PricingOptions
                    quote={this.props.quote} 
                    onQuantitySelected={this.props.onQuantityChanged} 
                    quantity={this.props.quantity} 
                    subtotal={this.props.subtotal}
                    />
            </div>
        );
    }
}

export default QuantityOptions;