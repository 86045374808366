import React, { Component, Fragment } from 'react'

export default class MailingAddress extends Component {
  render() {
      const {regarding, includeEmail} = this.props;
    return (
        <address>
            <em>MakerVision, LLC</em><br/>
            {regarding &&
                <Fragment>
                    re: {regarding}<br/>
                </Fragment>
            }
            35475 Spatterdock Ln<br/>
            Solon, OH 44139<br/>
            {includeEmail && 
                <a href="mailto: kyle@makervision.io">kyle@makervision.io</a>
            }
        </address>
    )
  }
}
