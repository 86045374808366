import React, { Component } from 'react'

export default class SweepstakesWinnersPage extends Component {
  render() {
    return (
        <main>
            <section className="content text-page">
                <h1>Sweepstakes Winners List</h1>

                <p>
                    Our first sweepstakes is taking place in November, 2018.  When our first winner is announced in December, 2018
                    this page will be updated with the winner's information.  Check back soon!
                </p>
            </section>
        </main>
    )
  }
}
