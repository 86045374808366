import { apiUrl } from '../environment';

export const SET_EMAIL = 'SET_EMAIL';
export function setEmail(email) {
    return { type: SET_EMAIL, email };
}

export const DISMISS_THANKS = 'DISMISS_THANKS';
export function dismissThanks() {
    return { type: DISMISS_THANKS };
}

export const REQUEST_SUBSCRIBE = 'REQUEST_SUBSCRIBE';
function requestSubscribe(email) {
    return { type: REQUEST_SUBSCRIBE, email };
}

export const RECEIVE_SUBSCRIBE = 'RECEIVE_SUBSCRIBE';
function receiveSubscribe(email) {
    return { type: RECEIVE_SUBSCRIBE, email };
}

export function subscribe(email) {
    return dispatch => {
        dispatch(requestSubscribe(email));
        fetch(apiUrl + 'subscription', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json;' },
            mode: 'cors',
            cache: 'no-cache',
            body: JSON.stringify(email)
        })
        .then(() => dispatch(receiveSubscribe(email)))
        .catch(console.error);
    }
}