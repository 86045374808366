import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import root from "./reducers";

const loggerMiddleware = createLogger();

function configureStore(preloadedState) {
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(root, preloadedState, composeEnhancers(
        applyMiddleware(
            thunkMiddleware,
            loggerMiddleware
        )
    ));
    return store;
}

const store = configureStore();
export default store;