import { combineReducers } from 'redux';
import order from './order';
import mailingList from './mailingList';
import blog from './blog';
import authentication from './authentication';

const root = combineReducers({ 
    order, 
    mailingList, 
    blog,
    authentication
});

export default root;