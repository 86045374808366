import React, { Component } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';

import { formatDollars } from '../lib/format';

import './PricingOptions.css';

class PricingOptions extends Component {
    selectQuantity = (quantity) => {
        this.props.onQuantitySelected(quantity);
    }
    render() {
        const { quote, quantity } = this.props;
        if (!quote || !quote.quotedVolumePrices)
            return (
                <div>
                </div>
            );

        const volumePrices = quote.quotedVolumePrices.map(vp => {
            const isSelected = vp.minimumQuantity <= quantity && vp.maximumQuantity >= quantity;
            let className = "price";
            if (isSelected) {
                className += " selected";
            }
            return (
                <div className={className} key={vp.id} onClick={this.selectQuantity.bind(this, vp.minimumQuantity)}>
                    <div className="price-label">
                        {vp.minimumQuantity}-{vp.maximumQuantity}&nbsp;
                        pcs
                    </div>
                    <div className="price-amount">
                        {formatDollars(vp.pricePerUnit)}
                    </div>
                    <div className="price-label">
                        each
                    </div>
                </div>
            );
        });
        return (
            <div>
                <FormControl margin="normal" fullWidth>
                    <FormLabel filled style={{
                        fontSize: 16,
                        transform: "translate(0, 1.5px) scale(0.75)",
                        transformOrigin: "top left",
                    }}
                    >Pricing Options</FormLabel>
                    <div className="prices">
                        {volumePrices}
                    </div>
                </FormControl>
                <FormControl margin="normal" fullWidth>
                    <FormLabel filled style={{
                        fontSize: 16,
                        transform: "translate(0, 1.5px) scale(0.75)",
                        transformOrigin: "top left",
                        marginBottom: ".5em",
                    }}
                    >Order Total</FormLabel>
                    <span className="total">{formatDollars(this.props.subtotal)}</span>
                    <span className="subtle">includes S&amp;H and tax.</span>
                </FormControl>
            </div>
        );
    }
}

export default PricingOptions;