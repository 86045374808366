import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import MailingAddress from '../MailingAddress';

export default class GiveawayDetailsPage extends Component {
  render() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth();
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 1);
    endDate.setDate(endDate.getDate() - 1);
    return (
      <main>
        <section className="content text-page">
            <h1>MONTHLY SWEEPSTAKES - OFFICIAL RULES</h1>
            <p>
              NO PURCHASE NECESSARY TO ENTER OR WIN. MAKING A PURCHASE OR PAYMENT OF ANY KIND WILL NOT INCREASE YOUR 
              CHANCES OF WINNING. VOID WHERE PROHIBITED OR RESTRICTED BY LAW.
            </p>

            <h2>Promotion Description</h2>
            <p>
              The current MONTHLY SWEEPSTAKES ("Sweepstakes") begins on <em>{startDate.toDateString()} at 00:00:00 EST</em> and ends 
              on <em>{endDate.toDateString()} at 23:59:59</em> (the "Promotion Period"). 
            </p>
            <p>
              The sponsor of this Sweepstakes is MakerVision, LLC dba: Penumbra Cuts ("Sponsor"). By participating in the 
              Sweepstakes, each Entrant unconditionally accepts and agrees to comply with and abide by these Official Rules
              and the decisions of Sponsor, which shall be final and binding in all respects. Sponsor is responsible for the 
              collection, submission or processing of Entries and the overall administration of the Sweepstakes. Entrants 
              should look solely to Sponsor with any questions, comments or problems related to the Sweepstakes. Sponsor may 
              be reached by email at <a href="mailto:kyle@makervision.io">kyle@makervision.io</a> during the Promotion Period. 
            </p>

            <h2>Eligibility</h2>
            <p>
                Open to legal residents of the United States who are 18 or older (the "Entrant"). Sponsor, and their 
                respective parents, subsidiaries, affiliates, distributors, retailers, sales representatives, advertising 
                and promotion agencies and each of their respective officers, directors and employees (the "Promotion Entities"), 
                are ineligible to enter the Sweepstakes or win a prize. Household Members and Immediate Family Members of such 
                individuals are also not eligible to enter or win. "Household Members" shall mean those people who share the same 
                residence at least three months a year. "Immediate Family Members" shall mean parents, step-parents, legal guardians,
                children, step-children, siblings, step-siblings, or spouses. This Sweepstakes is subject to all applicable federal, 
                state and local laws and regulations and is void where prohibited or restricted by law. 
            </p>

            <h2>Prizes</h2>
            <p>
                <em>Grand Prize:</em> One (1) winner will receive one (1) Arduino Uno R3 (approximate retail value or "ARV": $20)
            </p>
            <p>
              Only one prize per person and per household will be awarded. Gift cards and gift certificates are subject to the terms 
              and conditions of the issuer. Prizes cannot be transferred, redeemed for cash or substituted by winner. Sponsor 
              reserves the right in its sole and absolute discretion to award a substitute prize of equal or greater value if a 
              prize described in these Official Rules is unavailable or cannot be awarded, in whole or in part, for any reason. The 
              ARV of the prize represents Sponsor's good faith determination. That determination is final and binding and cannot be 
              appealed. If the actual value of the prize turns out to be less than the stated ARV, the difference will not be awarded 
              in cash. Sponsor makes no representation or warranty concerning the appearance, safety or performance of any prize 
              awarded. Restrictions, conditions, and limitations may apply. Sponsor will not replace any lost or stolen prize items.
            </p>
            <p>
              This Sweepstakes is open to legal residents of the United States and Prize will only be awarded and/or delivered to 
              addresses within said locations. All federal, state and/or local taxes, fees, and surcharges are the sole 
              responsibility of the prize winner. Failure to comply with the Official Rules will result in forfeiture of the prize.
            </p>
    
            <h2>How to Enter</h2>
            <p>
              Enter the Sweepstakes during the Promotion Period online by visiting the entry form, which can be found on
              the <Link to="/">Penumbra Cuts Home Page</Link>.
            </p>
            <p>
              Automated or robotic Entries submitted by individuals or organizations will be disqualified. Internet entry 
              must be made by the Entrant. Any attempt by Entrant to obtain more than the stated number of Entries by using 
              multiple/different email addresses, identities, registrations, logins or any other methods, including, but not 
              limited to, commercial contest/sweepstakes subscription notification and/or entering services, will void 
              Entrant's Entries and that Entrant may be disqualified. Final eligibility for the award of any prize is 
              subject to eligibility verification as set forth below. All Entries must be posted by the end of the Promotion 
              Period in order to participate. Sponsor's database clock will be the official timekeeper for this Sweepstakes.
            </p>
    
            <h2>Winner Selection</h2>
            <p>
              The Winner(s) of the Sweepstakes will be selected in a random drawing from among all eligible Entries received 
              throughout the Promotion Period. The random drawing will be conducted about the 1st business day after the 
              Promotion Period by Sponsor or its designated representatives, whose decisions are final. Odds of winning will 
              vary depending on the number of eligible Entries received.
            </p>

            <h2>Winner Notification</h2>
            <p>
              Winner will be notified by email at the email address provided in the Entry Information approximately 1 business 
              day after the random drawing. Potential Winner must accept a prize by email as directed by Sponsor within 7 days 
              of notification. Sponsor is not responsible for any delay or failure to receive notification for any reason, 
              including inactive email account(s), technical difficulties associated therewith, or Winner’s failure to 
              adequately monitor any email account.
            </p>
            <p>
              Any winner notification not responded to or returned as undeliverable may result in prize forfeiture. The 
              potential prize winner may be required to sign and return an affidavit of eligibility and release of 
              liability, and a Publicity Release (collectively "the Prize Claim Documents"). No substitution or 
              transfer of a prize is permitted except by Sponsor.
            </p>

            <h2>Privacy</h2>
            <p>
              Any personal information supplied by you will be subject to the privacy policy of the Sponsor. By entering 
              the Sweepstakes, you grant Sponsor permission to share your full name, city, state and other personally 
              identifiable information with the other Sweepstakes Entities for the purpose of administration and prize 
              fulfillment, including use in a publicly 
              available <Link to="/sweepstakes-winners">Winners List</Link> (we will not share your street address or email address).
            </p>

            <h2>Limitation of Liability</h2>
            <p>
              Sponsor assumes no responsibility or liability for (a) any incorrect or inaccurate entry information, or 
              for any faulty or failed electronic data transmissions; (b) any unauthorized access to, or theft, destruction 
              or alteration of entries at any point in the operation of this Sweepstakes; (c) any technical malfunction, 
              failure, error, omission, interruption, deletion, defect, delay in operation or communications line failure, 
              regardless of cause, with regard to any equipment, systems, networks, lines, satellites, servers, camera, 
              computers or providers utilized in any aspect of the operation of the Sweepstakes; (d) inaccessibility or 
              unavailability of any network or wireless service, the Internet or website or any combination thereof; 
              (e) suspended or discontinued Internet, wireless or landline phone service; or (f) any injury or damage 
              to participant's or to any other person’s computer or mobile device which may be related to or resulting 
              from any attempt to participate in the Sweepstakes or download of any materials in the Sweepstakes.
            </p>
            <p>
              If, for any reason, the Sweepstakes is not capable of running as planned for reasons which may include 
              without limitation, infection by computer virus, tampering, unauthorized intervention, fraud, technical 
              failures, or any other causes which may corrupt or affect the administration, security, fairness, 
              integrity or proper conduct of this Sweepstakes, the Sponsor reserves the right at its sole discretion to 
              cancel, terminate, modify or suspend the Sweepstakes in whole or in part. In such event, Sponsor shall 
              immediately suspend all drawings and prize awards, and Sponsor reserves the right to award any remaining 
              prizes (up to the total ARV as set forth in these Official Rules) in a manner deemed fair and equitable 
              by Sponsor. Sponsor and Released Parties shall not have any further liability to any participant in 
              connection with the Sweepstakes.
            </p>
  
            <h2>Winner List &amp; Official Rules</h2>
            <p>
              To obtain a copy of the Winner List or a copy of these Official Rules, send your request along with a 
              stamped, self-addressed envelope to:
            </p> 
            <MailingAddress 
              regarding="Monthly Sweepstakes" />
            <p>
              Requests for the names of the winners must be received no later than 30 days after 
              the Promotion Period has ended. For accessing a Winner List online, visit our Winner’s List. The winner 
              list will be posted after winner confirmation is complete.
            </p>

            <h2>Sponsor</h2>
            <p>
              This sweepstakes is sponsored by MakerVision, LLC doing business as Penumbra Cuts.
            </p>
            <MailingAddress includeEmail />
        </section>
      </main>
    )
  }
}
