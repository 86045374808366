import {parse} from '../lib/markdown';

export const REQUEST_BLOG_POST= 'REQUEST_BLOG_POST';
function requestBlogPost() {
    return { type: REQUEST_BLOG_POST };
}

export const RECEIVE_BLOG_POST= 'RECEIVE_BLOG_POST';
function receiveBlogPost(blog) {
    return { type: RECEIVE_BLOG_POST, blog };
}

export function fetchBlogPost(slug) {
    return dispatch => {
        dispatch(requestBlogPost(slug));
        fetch(`/blog/${slug}/post.md`, {
            method: 'GET',
            headers: { 'Content-Type': 'text/markdown; charset=UTF-8' },
            mode: 'cors',
        })
        .then((res) => res.text())
        .then((markdown) => parse(markdown))
        .then((blog) => dispatch(receiveBlogPost(blog)))
        .catch(console.error);
    }
}