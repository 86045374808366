
import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import StateSelector from './StateSelector';

import './AddressInput.css';

class AddressInput extends Component {
    handleChange = (event) => {
        this.props.setAddressField(event.target.name, event.target.value);
    }
    handleStateSelected = (id) => {
        this.props.setAddressField('stateId', id);
    }
    render() {
        return (
            <div>
                <div className="name-inputs">
                    <FormControl style={{width: "calc(50% - 10px)", marginRight: 10}}>
                        <TextField 
                            id="first-name"
                            label="First Name"
                            name="firstName"
                            value={this.props.firstName}
                            onChange={this.handleChange}
                            autoFocus
                            margin="normal"
                            />
                    </FormControl>
                    <FormControl style={{width: "50%"}}>
                        <TextField 
                            id="last-name"
                            name="lastName"
                            label="Last Name"
                            value={this.props.lastName}
                            onChange={this.handleChange}
                            margin="normal"
                            />
                    </FormControl>
                </div>
                <FormControl style={{width: "100%"}}>
                    <TextField 
                        id="email"
                        name="email"
                        label="Email Address"
                        type="email"
                        value={this.props.emailAddress}
                        onChange={this.handleChange}
                        margin="normal"
                        />
                </FormControl>
                <FormControl style={{width: "100%"}}>
                    <TextField 
                        id="address-line-1"
                        name="addressLine1"
                        label="Address Line 1"
                        value={this.props.addressLine1}
                        onChange={this.handleChange}
                        margin="normal"
                        />
                </FormControl>
                <FormControl style={{width: "100%"}}>
                    <TextField 
                        id="address-line-2"
                        name="addressLine2"
                        label="Address Line 2"
                        value={this.props.addressLine2}
                        onChange={this.handleChange}
                        margin="normal"
                        />
                </FormControl>
                <FormControl style={{width: "calc(34% - 10px)", marginRight: 10}}>
                    <TextField 
                        id="city"
                        name="city"
                        label="City"
                        value={this.props.city}
                        onChange={this.handleChange}
                        margin="normal"
                        />
                </FormControl>
                <StateSelector 
                    states={this.props.states}
                    stateId={this.props.stateId}
                    fetchStates={this.props.fetchStates}
                    onStateSelected={this.handleStateSelected}
                    />
                <FormControl style={{width: "33%"}}>
                    <TextField 
                        id="zip-code"
                        name="zipCode"
                        label="Zip Code"
                        value={this.props.zipCode}
                        onChange={this.handleChange}
                        margin="normal"
                        />
                </FormControl>
            </div>
        );
    }
}

export default AddressInput;