import { 
    SET_MATERIAL_TYPE, 
    REQUEST_MATERIAL_TYPES, 
    RECEIVE_MATERIAL_TYPES,
    SET_STOCK_MATERIAL,
    REQUEST_STOCK_MATERIALS,
    RECEIVE_STOCK_MATERIALS,
    SET_QUANTITY,
    REQUEST_FILE_SUBMISSION,
    RECEIVE_FILE_SUBMISSION,
    REQUEST_QUOTE,
    RECEIVE_QUOTE,
    BACK_STEP,
    NEXT_STEP,
    SET_ADDRESS_FIELD,
    REQUEST_STATES,
    RECEIVE_STATES,
    REQUEST_PAYMENT_TOKEN,
    RECEIVE_PAYMENT_TOKEN,
    REQUEST_PLACE_ORDER,
    RECEIVE_PLACE_ORDER,
    HIDE_ORDER_CONFIRMATION,
} from '../actions/order';

const defaultOrderState = {
    step: 0,

    fileSubmissionId: '',
    filePreviewImage: '',
    fileWidth: '',
    fileHeight: '',

    materialTypeId: '',
    materialTypes: [],

    stockMaterialId: '',
    stockMaterials: [],

    quantity: 1,

    quote: null,

    firstName: '',
    lastName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    zipCode: '',
    stateId: '',

    states: [],

    paymentToken: '',
    placedOrderId: null,
    displayOrderConfirmation: false,
};

const order = (state = defaultOrderState, action) => {
    switch (action.type) {
        case SET_MATERIAL_TYPE:
            return Object.assign({}, state, { materialTypeId: action.id });
        case REQUEST_MATERIAL_TYPES:
            return Object.assign({}, state, { isFetchingMaterialTypes: true });
        case RECEIVE_MATERIAL_TYPES:
            return Object.assign({}, state, { isFetchingMaterialTypes: false, materialTypes: action.materialTypes });
        case SET_STOCK_MATERIAL:
            return Object.assign({}, state, { stockMaterialId: action.id });
        case REQUEST_STOCK_MATERIALS:
            return Object.assign({}, state, { isFetchingStockMaterials: true });
        case RECEIVE_STOCK_MATERIALS:
            return Object.assign({}, state, { isFetchingStockMaterials: false, stockMaterials: action.stockMaterials });
        case SET_QUANTITY:
            return Object.assign({}, state, { quantity: action.quantity });
        case REQUEST_FILE_SUBMISSION:
            return Object.assign({}, state, { isSubmittingFile: true });
        case RECEIVE_FILE_SUBMISSION:
            return Object.assign({}, state, { isSubmittingFile: false, fileSubmissionId: action.id, fileWidth: action.width, fileHeight: action.height, filePreviewImage: action.preview });
        case REQUEST_QUOTE:
            return Object.assign({}, state, { isFetchingQuote: true });
        case RECEIVE_QUOTE:
            return Object.assign({}, state, { isFetchingQuote: false, quote: action.quote });
        case BACK_STEP:
                return Object.assign({}, state, { step: state.step - 1 });
        case NEXT_STEP:
                return Object.assign({}, state, { step: state.step + 1 });
        case SET_ADDRESS_FIELD:
            const updates = {};
            updates[action.key] = action.val;
            return Object.assign({}, state, updates);
        case REQUEST_STATES:
            return Object.assign({}, state, { isFetchingStates: true });
        case RECEIVE_STATES:
            return Object.assign({}, state, { isFetchingStates: false, states: action.states });
        case REQUEST_PAYMENT_TOKEN:
            return Object.assign({}, state, { isFetchingPaymentToken: true });
        case RECEIVE_PAYMENT_TOKEN:
            return Object.assign({}, state, { isFetchingPaymentToken: false, paymentToken: action.paymentToken });
        case REQUEST_PLACE_ORDER:
            return Object.assign({}, state, { isFetchingPaymentToken: true });
        case RECEIVE_PLACE_ORDER:
            return Object.assign({}, defaultOrderState, { 
                placedOrderId: action.orderDetails.id, 
                displayOrderConfirmation: true 
            });
        case HIDE_ORDER_CONFIRMATION:
            return Object.assign({}, defaultOrderState, { 
                displayOrderConfirmation: false
            });
        default:
            return state;
    }
}

export default order;