import React, { Component } from 'react';
import {
    injectStripe,
    CardElement
} from 'react-stripe-elements';

import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';

import './StripeCardInput.css';
import stripeLogo from './powered-by-stripe.svg';

const cardStyles = {
    base: {
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        fontSize: '16px',
    }
};

class StripeCardInput extends Component {
    componentDidMount() {
        window.stripe = this.props.stripe;
    }
    render() {
        return (
            <FormControl margin="normal" fullWidth>
                <FormLabel filled style={{
                    fontSize: 16, 
                    transform: "translate(0, 1.5px) scale(0.75)",
                    transformOrigin: "top left",
                    marginBottom: 10}}
                    >Credit or Debit Card</FormLabel>
                <CardElement
                    style={cardStyles}
                    hidePostalCode />
                <a href="https://stripe.com" style={{marginTop: 5}}>
                    <img src={stripeLogo} alt="Powered by Stripe" style={{float: 'right', height: 20 }}/>
                </a>
            </FormControl>
        );
    }
}

export default injectStripe(StripeCardInput);