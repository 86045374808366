import React, { Component, Fragment } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Pulse from 'react-reveal/Pulse';

import OrderWizardContainer from '../order/OrderWizardContainer';

class CallToAction extends Component {
    constructor() {
        super();
        this.state = {
            open: false
        };
    }
    showDialog = () => {
        this.setState({open: true});
    }
    handleClose = () => {
        this.setState({open: false});
    }
    render() {
        const ctaStyle = {
            fontSize: '1.2rem',
            padding: '1rem'
        };
        return (
            <Fragment>
                <Pulse forever duration={2000}>
                    <Button variant="outlined" size="large" style={ctaStyle} color="inherit" onClick={this.showDialog}>
                        Get an Immediate Quote!
                    </Button>
                </Pulse>
                <Dialog aria-labelledby="dialog-title" open={this.state.open} onClose={this.handleClose} scroll="body" >
                    <DialogTitle id="dialog-title">Order Laser Cut Parts</DialogTitle>
                    <DialogContent>
                        <OrderWizardContainer />
                    </DialogContent>
                </Dialog>
            </Fragment>
        );
    }
}

export default CallToAction;