import React, { Component } from 'react';
import SubscribeForm from './SubscribeForm';
import SubscribedThankYou from './SubscribedThankYou';

import Paper from '@material-ui/core/Paper';

import './Subscribe.css';

class SubscribeSection extends Component {
    render() {
        return (
            <Paper className='subscribe-paper'>
                {this.props.thanksVisible ? 
                    <SubscribedThankYou dismissThanks={this.props.dismissThanks} /> :
                    <SubscribeForm setEmail={this.props.setEmail} subscribe={this.props.subscribe} />
                }
            </Paper>
        );
    }
}

export default SubscribeSection;