import React, { Component } from 'react';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Files from 'react-files';

import UseSampleFileButton from './UseSampleFileButton';

import './FileSubmitter.css';

class FileSubmitter extends Component {
    handleFileChange = (fileSelections) => {
        const file = fileSelections[0];
        this.props.onFileSelected(file);
    }

    render() {
        let imagePreview;
        if (this.props.fileSubmissionId !== '') {
            imagePreview = (
                <div>
                    <div className="file-preview" dangerouslySetInnerHTML={{ __html: this.props.filePreviewImage }} />
                    <div className="file-size"><span className="size width">{this.props.fileWidth}</span>W&nbsp; x <span className="size height">{this.props.fileHeight}</span>&nbsp;H</div>
                </div>
            );
        } else {
            imagePreview = <CloudUpload color="primary" style={{ fontSize: '6rem' }} />;
        }
        return (
            <div className="files-container">
                <UseSampleFileButton onClick={this.props.loadSample} />
                <Files
                    onChange={this.handleFileChange}
                    onError={console.error}
                    accepts={['.dxf']}
                    maxFileSize={10000000}
                    minFileSize={0}
                    multiple={false}
                    clickable
                >
                    {imagePreview}
                    <p style={{textAlign: 'center'}}>Drop DXF file here or click to select.</p>
                </Files>
            </div>
        );
    }
}

export default FileSubmitter;