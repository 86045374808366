import { connect } from "react-redux";
import OrderWizard from "./OrderWizard";
import { 
    backStep, 
    nextStep, 
    fetchPlaceOrder, 
    hideOrderConfirmation 
} from "../actions/order";
import { getSubtotal } from "../selectors";

const mapStateToProps = function (state) {
    return { 
        subtotal: getSubtotal(state),
        ...state.order 
    };
}

const mapDispatchToProps = dispatch => ({
    backStep: () => dispatch(backStep()),
    nextStep: () => dispatch(nextStep()),
    placeOrder: (order) => dispatch(fetchPlaceOrder(order)),
    hideOrderConfirmation: () => dispatch(hideOrderConfirmation())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderWizard);

