import React, { Component } from 'react'

import {openChat} from './drift';

export default class ContactLink extends Component {
  render() {
    return (
      // eslint-disable-next-line 
      <a onClick={openChat}>
        {this.props.children}
      </a>
    )
  }
}
