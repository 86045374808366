import store from '../store';
import Auth0Lock from 'auth0-lock';
import { receiveToken, receiveProfile } from './authentication';

const lock = new Auth0Lock(
    'YwFWl6L1LnIAS9uyopjflLp83tEfoUuE',
    'dronebuildertools.auth0.com'
);

lock.on("authenticated", function (authResult) {
    store.dispatch(receiveToken(authResult.accessToken));
    lock.getUserInfo(authResult.accessToken, function (error, profile) {
        if (error) {
            console.error(error);
            return;
        }
        store.dispatch(receiveProfile(profile));
        localStorage.setItem('accessToken', authResult.accessToken);
        localStorage.setItem('profile', JSON.stringify(profile));
    });
});

export default lock;