import { connect } from "react-redux";
import { setEmail, dismissThanks, subscribe } from "../actions/subscribe";
import Subscribe from "./Subscribe";

const mapStateToProps = function (state) {
    return { ...state.mailingList };
}

const mapDispatchToProps = dispatch => ({
    setEmail: (email) => dispatch(setEmail(email)),
    dismissThanks: () => dispatch(dismissThanks()),
    subscribe: (email) => dispatch(subscribe(email)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Subscribe);
