import { connect } from "react-redux";
import { fetchBlogPost } from "../actions/blog";
import PostPage from "./PostPage";

const mapStateToProps = function (state) {
    return { ...state.blog };
}

const mapDispatchToProps = dispatch => ({
    fetchBlogPost: (slug) => dispatch(fetchBlogPost(slug)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PostPage);


