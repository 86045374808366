import { connect } from "react-redux";
import OrderOptions from "./OrderOptions";
import { 
    setMaterialType, 
    fetchMaterialTypes,
    setStockMaterial,
} from "../actions/order";
import { getSubtotal } from "../selectors";

const mapStateToProps = function (state) {
    return { 
        subtotal: getSubtotal(state),
        ...state.order 
    };
}

const mapDispatchToProps = dispatch => ({
    onMaterialTypeChanged: id => dispatch(setMaterialType(id)),
    onStockMaterialChanged: id => dispatch(setStockMaterial(id)),
    fetchMaterialTypes: () => dispatch(fetchMaterialTypes()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderOptions);