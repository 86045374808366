import React, { Component } from 'react';
import {Link} from 'react-router-dom';

import ContactLink from './ContactLink';

import './Footer.css';

class Footer extends Component {
    render() {
        const year = new Date().getFullYear();
        return (
            <footer className="app-footer">
                <nav>
                    <div>
                        <h4>Explore</h4>
                        <Link to="/">Home</Link>
                        <Link to="/blog/laser-cut-fall-crafting-ideas">Blog</Link>                            
                        <Link to="/faq">FAQ</Link>                            
                    </div>
                    <div>
                        <h4>Giveaways</h4>
                        <Link to="/sweepstakes-details">Official Rules</Link>
                        <Link to="/sweepstakes-winners">Winner List</Link>
                    </div>
                    <div>
                        <h4>Help</h4>
                        <ContactLink>Contact Us</ContactLink>
                        <Link to="/legal">Legal</Link>                            
                        <Link to="/privacy-policy">Privacy Policy</Link>                            
                        <Link to="/terms-and-conditions">Terms &amp; Conditions</Link>                            
                    </div>
                    <div>
                        <h4>Educators</h4>
                        <Link to="/sumo-bots">Sumo Bots</Link>
                        <Link to="/laser-buying-guide">Laser Buying Guide</Link>
                    </div>
                </nav>
                <div className="copyright">
                    &copy; {year} MakerVision, LLC. USA. All Rights Reserved.
                </div>
            </footer>
        );
    }
}

export default Footer;