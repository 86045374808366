import React, { Component } from 'react'

export default class TermsPage extends Component {
    render() {
        return (
            <main>
                <section className="content text-page">
                    <h1>Terms of Use and Purchase Conditions</h1>
                    <p>
                        The purpose of Penumbra Cuts&trade; (AKA MakerVision, LLC&trade;) is to offer a service to manufacture personalized goods online easily, with quality and at a fair
                        price. No heavy commercials. No unnecessary waiting. No unexpected extra costs. We know what you want, we know how you want it and we love serving you.
                    </p>
                    <p>
                        PLEASE READ THIS DOCUMENT CAREFULLY! IT CONTAINS IMPORTANT INFORMATION ABOUT YOUR RIGHTS, RESPONSIBILITIES, OBLIGATIONS, LIMITATIONS AND EXCLUSIONS THAT
                        WILL APPLY TO YOU AS A USER AND CUSTOMER.
                    </p>
                    <p>
                        This document, Terms of Use and Conditions of Purchase, is the agreement between PenumbraCuts.com (hereinafter "Penumbra") and the beneficiary of the
                        order (hereinafter "you" or "client / user"), whether a person by individual capacity or as representation of third parties, companies or other legal entities.
                    </p>
                    <h2>1. ORDERS, ONLINE PLATFORM AND PAYMENTS</h2>
                    <h3>1.1 ACCEPTANCE OF THE TERMS</h3>
                    <p>
                        By placing an order in Penumbra Cuts&trade; you confirm that you have read and understood all the points set out in this document and accept their application.
                    </p>
                    <h3>1.2. CHANGES IN DESCRIPTIONS AND PRICES</h3>

                    <p>
                        All information reflected in Penumbra Cuts&trade; is subject to change. Penumbra Cuts&trade; is not responsible for typographical errors or images as well as their misinterpretation. Penumbra Cuts&trade; reserves the right to refuse orders resulting from such errors.
                    </p>
                    <h3>1.3. ONLINE BUDGET</h3>

                    <p>
                        Penumbra Cuts&trade; generates automatic prices through the documents and options that you add and select in the configurator. The prices offered by the configurator are subject to changes either due to external causes and / or internal to Penumbra Cuts&trade;. Penumbra Cuts&trade; reserves the right to change the amount of an order if it is affected by a programmed rate change, change in the price of the raw material, variation of transport or customs fees, market fluctuations, changes at your request via email or to an error on your part in the budget interface itself.
                    </p>

                    <p>
                        You understand that by accepting the conditions and making the payment, you are accepting the budget made and displayed on the web.
                    </p>

                    <p>
                        If Penumbra Cuts&trade; detects an error in the price, the new amount will be notified via mail and a payment method will be provided. You will have 3 working days to accept this new price and make the payment. Otherwise the order will be rejected.
                    </p>

                    <h3>1.4. DATA AND CHECKLIST</h3>

                    <p>
                        You agree to review and ensure that all files, options and data inserted in Penumbra Cuts&trade; are correct. Penumbra Cuts&trade; is not responsible for errors or misinterpretations of the order arising from errors in the insertion of files, options and data by you.
                    </p>

                    <h3>1.5. REALIZATION OF THE ORDER</h3>

                    <p>
                        After the confirmation of an order in Penumbra Cuts&trade;, a production order will automatically be generated in our facilities and the performance of a consequent work.
                    </p>

                    <h3>1.6. PAYMENTS</h3>

                    <p>
                        Penumbra Cuts&trade; includes payments made with VISA®, MASTERCARD®, AMERCAN EXPRESS®, DISCOVER NETWORK®, DINERS CLUB® and JCB®, through the Stripe® platform.
                    </p>

                    <p>
                        In Penumbra Cuts&trade; the payment is charged to the account indicated by you through PayPal®. The platform makes the transaction instantly. You agree to have the necessary amount in the account indicated for your transaction, otherwise PayPal® will detect an error in the transaction and the order will be rejected.
                    </p>
                    <p>

                        If you detect any error in the payment process or the charge to your account, contact us through our contact section.
                    </p>

                    <h3>1.7. AUTOMATIC CONFIRMATION OF ORDER ISSUANCE</h3>

                    <p>
                        All orders generate a confirmation email to the address that you have indicated as a contact. If you do not receive the confirmation email within 24 hours of the completion of your order, make sure that the Penumbra Cuts&trade; mail address is not blocked by any anti-spam filter or that the confirmation email has not ended in a directory of unwanted emails or deleted emails, such as trash. If after 24 hours you have not received or found the confirmation email, please get in touch with us through our contact section of Penumbra Cuts&trade;. DO NOT MAKE THE ORDER AGAIN, it could generate duplication of orders and duplication of payments. Make the order again only if Penumbra Cuts&trade; confirms this via email. If you accidentally duplicate an order, notify Penumbra Cuts&trade; of that event IMMEDIATELY. Otherwise, both orders will be processed.
                    </p>

                    <h3>1.8. CONFIRMATION OF ACCEPTANCE / REJECTION OF ORDERS</h3>

                    <p>
                        Penumbra Cuts&trade; reserves the right to accept / reject orders. You will be notified via email, during the following 24 hours after receiving the email of automatic confirmation of the issuance of the order, of the preference taken, being able to develop the following two scenarios.
                    </p>

                    <h4>1.8.1. ACCEPTANCE OF THE ORDER</h4>

                    <p>
                        The order will begin to take shape in our facilities after said mail and will be notified of it.
                    </p>

                    <h4>1.8.2. REFUSAL OF THE ORDER</h4>

                    <p>
                        Notification will be made via email of the cause of said rejection, will be informed of the measures to be taken to adapt the order for acceptance.
                    </p>

                    <h3>1.9. SHIPPING</h3>

                    <p>
                        You have multiple shipping options depending on the urgency of the order. Penumbra Cuts&trade; is not responsible for delays in deliveries due to agents external to its control, including, but not limited to: Customs stops, security controls, etc.
                    </p>

                    <h2>2. CANCELLATION AND MODIFICATION OF AN ORDER</h2>

                    <h3>2.1. RETURNS AND MONEY RETURN</h3>
                    <p>
                        The service offered by Penumbra Cuts&trade; is personalized and made to order. All services are made to measure. The return of the goods is not an option; however, if your order presents inconsistencies, Penumbra Cuts&trade; will consider several eventualities that may result in a refund of the amount of the affected order. Contact us if there is any inconsistency in your order, through our contact section.
                    </p>

                    <h3>2.2. REJECTION OR MODIFICATION OF ORDERS BY Penumbra Cuts&trade;</h3>

                    <p>
                        Penumbra Cuts&trade; reserves the right to cancel orders in which, for economic, technical or other reasons, are impossible to perform.
                    </p>

                    <h4>2.2.1. REJECTION OF THE ORDER BY Penumbra Cuts&trade;</h4>

                    <p>
                        Penumbra Cuts&trade; reserves the right to cancel orders, in which case, we will proceed to the full refund of the paid amount.
                    </p>

                    <h4>2.2.2. PROPOSAL FOR MODIFICATION OF THE ORDER BY Penumbra Cuts&trade;</h4>

                    <p>
                        Penumbra Cuts&trade; reserves the right to propose changes to orders. The affected items will be notified via e-mail and the proposal for each of them, as well as the reason for the proposal. After acceptance by you, you will readjust the amount for each case.
                    </p>

                    <h3>2.3. REJECTION OR MODIFICATION OF ORDERS FOR YOU</h3>

                    <p>
                        The service offered by Penumbra Cuts&trade; is personalized and made to order. All services are made to measure. The rejection or modification by you of an order will proceed to the total or partial refund of the amount of the order. This amount will reflect the total charge of the order, except for the production processes already carried out. The scope of said rejection or modification will be assessed and the amount to be returned via email will be communicated to the client user. You agree to accept those charges due to rejection or modification of the order.
                    </p>

                    <h3>2.4. PROTOTYPES TEST</h3>

                    <p>
                        We love orders with a high number of units, but we want to make sure we are doing things right before starting to produce large batches. Penumbra Cuts&trade; reserves the right to pause an order where the technical difficulty or the number of units is greater than usual. Penumbra Cuts&trade; may propose via mail to the client user to carry out a manufacturing test prior to the original order. The amount of this test phase will be different depending on each case.
                    </p>

                    <h2>3. MATERIAL AND SPECIFICATIONS</h2>

                    <h3>3.1. ABSENCE OF SPECIFICATIONS</h3>

                    <p>
                        Penumbra Cuts&trade; includes various specifications about the industrial process carried out, such as Kerf, Cutting tolerance, etc. There is an absence of detailed specifications for most manufactures. Here is an example: ‘Remove the burr from a satined piece’. We will pass the piece through a polishing machine; this offers a degree of satin finish and makes a tiny chamfer on the edges of the piece, according to the global standard of the process. If you want any specification other than those shown in Penumbra Cuts&trade;, you must indicate them in the comments box of the Penumbra Cuts&trade; part configurator. Claims will not be accepted for expectations in specifications not shown in Penumbra Cuts&trade;.
                    </p>

                    <h3>3.2. PROPERTIES OF MATERIAL</h3>

                    <p>
                        All materials used by Penumbra Cuts&trade; are subject to tolerances and variations related to the manufacture, handling and storage of the material itself. You are aware of the existence of typical variations of the metals in the mentioned points, thus being able to affect the following characteristics, including but not limited to: color, thickness, weight, hardness, composition, texture, roughness, density, elastic modulus, conductivity thermal, coefficient of thermal expansion, electrical resistance, melting point, resistance to oxidation, magnetic capacity, torsion and chemical reactivity.
                    </p>

                    <p>
                        Any variations in the tolerances of these characteristics are in accordance with the standards of the international identity standard for the supply of said material or alloy.
                    </p>

                    <h3>3.3. IDENTITY OF THE MATERIAL</h3>

                    <p>
                        The identity of the materials used in the production processes of Penumbra Cuts&trade; are subject to the territorial norms of equivalences between identities of material. Penumbra Cuts&trade; will supply the same material that you choose for the production of your personalized pieces to match the international identity alphanumerically of the description of the material that appears in the Penumbra Cuts&trade; configurator. Here is an example: If you select AISI 304, Penumbra Cuts&trade; will arrange for the material to be AISI 304, this could be AISI 304L, AISI 304H ... You are aware that the supply of material certifies the identity up to the indicated digits in the configurator Penumbra Cuts&trade;, in the example; AISI 304 includes other subfamilies of the AISI 304 alloy.
                    </p>

                    <p>
                        You can consult the tables of material equivalences between territorial regulations in our MATERIALS section.
                    </p>

                    <h2>4. SECURITY</h2>

                    <h3>4.1. BASIC SAFETY CONSIDERATIONS</h3>
                    <p>
                        You are aware that Penumbra Cuts&trade; has no control over the design, use and misuse of the goods generated by the Penumbra Cuts&trade; service.
                    </p>

                    <p>
                        You have the responsibility to create a functional and safe design, insert it, if that is your purpose, into appropriate devices and safe equipment to safeguard the end user or other persons and properties against damages arising from their use, operation or particular configuration, and to properly comply with all federal, state, and local laws, rules, regulations, and safety regulations, and all industry safety standards.
                    </p>

                    <p>
                        Additionally, it is your responsibility to review the manufacturing carried out by Penumbra Cuts&trade; to validate the correct performance of its purpose, validating the factors: Including but not limited to: Manufacturing defects, material defects, mechanical resistance in general and all other factors that may affect your final purpose Penumbra Cuts&trade; will not be responsible in any way for the consequences or results obtained through the use of the Penumbra Cuts&trade; website or its products.
                    </p>

                    <p>
                        Penumbra Cuts&trade; will not be liable for any loss or damage suffered by you or by any other party as a result of the design, manufacture, use, misuse, installation or application of the custom parts. In addition, Penumbra Cuts&trade; shall be exempt from liability and liability charges for any claim arising out of, related to or related to the use, installation or application of the custom parts.
                    </p>

                    <h3>4.2. HEALTH / MOBILITY APPLICATIONS / OTHER CRITICAL SECURITY SECTORS</h3>

                    <p>
                        The goods arising from the Penumbra Cuts&trade; service are not intended for sale as parts, components or assemblies for the construction, maintenance, operation or use of nuclear facilities or weapon systems, nor for use in flight, navigation, communication or support equipment, parts of aircraft, used in a medical system, life support equipment or any application where the failure or malfunction of said piece manufactured by Penumbra Cuts&trade; could cause personal injury, by itself or its use in other applications or assemblies. You agree that, if you use the goods of the Penumbra Cuts&trade; service for such purposes, you do so at your own discretion and at your own risk and responsibility, and that Penumbra Cuts&trade; is not responsible for any claim or damage arising from such use. In addition, Penumbra Cuts&trade; shall be exempt from liability and liability charges for any claim arising out of, related to or related to the use, installation or application of the custom parts.
                    </p>

                    <h3>4.3. HELP AND ADVICE</h3>

                    <p>
                        Penumbra Cuts&trade; can offer help and advice to your questions, as well solving errors in the data, files and options sent to the Penumbra Cuts&trade; configurator. You assume full responsibility for the application, or not, of the correction proposed by Penumbra Cuts&trade;, and accept as your own and under your responsibility the design of the new resulting piece.
                    </p>

                    <h2>5. INTELLECTUAL PROPERTY</h2>

                    <h3>5.1. INFRINGEMENTS OF INTELLECTUAL PROPERTY</h3>
                    <p>
                        You warrant and declare that the manufacturing designs and other information related to the order, are original and of your property, designed and created by you or with the consent of the original author, that alone or incorporated into any system, product, device, device or mechanism, do not infringe or violate any right (including, among others, patent rights, copyrights or trade secrets) of any third party. In addition, it guarantees and declares that the designs do not infringe or violate any law, code or local, state, federal or international regulation. In addition, Penumbra Cuts&trade; shall be exempt from liability and liability charges for any claim arising out of, related to or related to the use, installation or application of the custom parts.
                    </p>

                    <h3>5.2. PERSONAL INFORMATION AND DESIGNS</h3>

                    <p>
                        You will retain all privacy rights of all information sent to Penumbra Cuts&trade;xxxxxxxxxx. You understand and accept that Penumbra Cuts&trade; may disclose to its employees and third parties the information necessary to provide services related to the creation, sending and correction of technical problems of your order. Your personal information will be handled in accordance with our Privacy Policy.
                    </p>

                    <h2>6. ABOUT THE TERMS OF USE AND TERMS OF PURCHASE</h2>

                    <h3>6.1. MODIFICATIONS TO THE TERMS OF USE AND PURCHASING CONDITIONS</h3>

                    <p>
                        The Terms of Use and Conditions of Purchase can not be waived or modified in any way, except by Penumbra Cuts&trade; and in writing. This clause of "non-oral modification" can not be waived, except for a written instrument signed by Penumbra Cuts&trade; or the modification of these terms
                    </p>

                    <p>
                        Penumbra Cuts&trade; reserves the right to modify this binding document to the orders, Terms of Use and Conditions of Purchase, without prior notice, either as per periodic review of the same or by a specific casuistry. The version of Terms of Use and Conditions of Purchase that will be applied to an order will be the one in force on the date of its processing.
                    </p>
                    <h3>6.2. EFFECTIVE DATE.</h3>
                    <p>
                        This binding document, Terms of Use and Conditions of Purchase, has been reviewed on the date indicated below, leaving any version with an earlier date obsolete and unenforceable.
                    </p>
                    <p>
                        Penumbra Cuts&trade;, rev. 10/24/2018.
                    </p>
                    <p>
                        PenumbraCuts.com&trade;. All Rights Reserved.
                    </p>
                </section>
            </main>
        )
    }
}
