import React, { Component } from 'react';
import './Title.css';

import {Link} from 'react-router-dom';

class Title extends Component {
    render() {
        return (
            <div className="title-container">
                <Link to="/" className="penumbra">
                    PENUMBRA
                </Link>
                <Link to="/" className="cuts">
                    cuts
                </Link>
            </div>
        );
    }
}

export default Title;