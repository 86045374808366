import React, { Component } from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

class MaterialTypeSelector extends Component {
    handleTypeChange = event => {
        const materialTypeId = event.target.value;
        this.props.onMaterialTypeSelected(materialTypeId);
    }
    componentDidMount() {
        this.props.fetchMaterialTypes();
    }
    render() {
        const styleFullWidth = { width: '100%' };
        const typeOptions = this.props.materialTypes.map(m => <MenuItem value={m.id} key={m.id}>{m.name}</MenuItem>);
        return (
                <FormControl style={styleFullWidth} margin="normal">
                    <InputLabel htmlFor="material">Material Type</InputLabel>
                    <Select 
                        value={this.props.materialTypeId || ''}
                        onChange={this.handleTypeChange}
                        inputProps={{ name: 'material', id: 'material' }}
                        className="material"
                        >
                        {typeOptions}
                    </Select>
                </FormControl>
        );
    }
}

export default MaterialTypeSelector;