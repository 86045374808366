import React, { Component } from 'react'

export default class LegalPage extends Component {
    render() {
        return (
            <main>
                <section className="content text-page">
                    <h1>Welcome to Penumbra Cuts (aka MakerVision, LLC)</h1>
                    <p>These terms and conditions outline the rules and regulations for the use of MakerVision, LLC (dba: Penumbra Cuts)'s Website.</p>
                    <br /> 
                    <span style={{textTransform: 'capitalize'}}> MakerVision, LLC (dba: Penumbra Cuts)</span> is located at:<br /> 
                    <address>Cleveland, Ohio - 44139, United States<br /></address>
                    <p>By accessing this website we assume you accept these terms and conditions in full. Do not continue to use MakerVision, LLC (dba: Penumbra Cuts)'s website 
                    if you do not accept all of the terms and conditions stated on this page.
                    </p>
                    <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice
                    and any or all Agreements: “Client”, “You” and “Your” refers to you, the person accessing this website
                    and accepting the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers
                    to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client
                    or ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake
                    the process of our assistance to the Client in the most appropriate manner, whether by formal meetings
                    of a fixed duration, or any other means, for the express purpose of meeting the Client’s needs in respect
                    of provision of the Company’s stated services/products, in accordance with and subject to, prevailing law
                    of United States. Any use of the above terminology or other words in the singular, plural,
                    capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.
                    </p>
                    <h2 id="cookies">Cookies</h2>
                    <p>We employ the use of cookies. By using MakerVision, LLC (dba: Penumbra Cuts)'s website you consent to the use of cookies 
                    in accordance with MakerVision, LLC (dba: Penumbra Cuts)’s privacy policy.
                    </p>
                    <p>Most of the modern day interactive web sites
                    use cookies to enable us to retrieve user details for each visit. Cookies are used in some areas of our site
                    to enable the functionality of this area and ease of use for those people visiting. Some of our 
                    affiliate / advertising partners may also use cookies.
                    </p>
                    <h2>IFrames</h2>
                    <p>Without prior approval and express written permission, you may not create frames around our Web pages or
                    use other techniques that alter in any way the visual presentation or appearance of our Web site.
                    </p>
                    <h2>Reservation of Rights</h2>
                    <p>We reserve the right at any time and in its sole discretion to request that you remove all links or any particular
                    link to our Web site. You agree to immediately remove all links to our Web site upon such request. We also
                    reserve the right to amend these terms and conditions and its linking policy at any time. By continuing
                    to link to our Web site, you agree to be bound to and abide by these linking terms and conditions.
                    </p>
                    <h2>Content Liability</h2>
                    <p>We shall have no responsibility or liability for any content appearing on your Web site. You agree to indemnify
                    and defend us against all claims arising out of or based upon your Website. No link(s) may appear on any
                    page on your Web site or within any context containing content or materials that may be interpreted as
                    libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or
                    other violation of, any third party rights.
                    </p>
                    <p>
                        We shall have no responsibility or liability for any death, personal injuries, financial losses resulting from
                        use of our component parts.  Use at your own risk.
                    </p>
                    <h2>Disclaimer</h2>
                    <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill). Nothing in this disclaimer will:</p>
                    <ol>
                    <li>limit or exclude our or your liability for death or personal injury resulting from negligence;</li>
                    <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                    <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                    <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                    </ol>
                    <p>The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a)
                    are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or
                    in relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort
                    (including negligence) and for breach of statutory duty.
                    </p>
                    <p>To the extent that the website and the information and services on the website are provided free of charge,
                    we will not be liable for any loss or damage of any nature.
                    </p>
                    <h2>Credit &amp; Contact Information</h2>
                    <p>This Terms and conditions page was created at <a href="https://termsandconditionstemplate.com">termsandconditionstemplate.com</a>
                    generator. If you have any queries regarding any of our terms, please contact us.
                    </p> 
                </section>
            </main>
        )
    }
}
