import { connect } from "react-redux";
import { authenticate, logout } from "./actions/authentication";
import Authenticator from "./Authenticator";

const mapStateToProps = function (state) {
    return { ...state.authentication };
}

const mapDispatchToProps = dispatch => ({
    authenticate: () => dispatch(authenticate()),
    logout: () => dispatch(logout()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Authenticator);

