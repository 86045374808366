import React, { Component } from 'react';
import './ImageSection.css';

class ImageSection extends Component {
    render() {
        return (
            <section className="image"
                style={{ backgroundImage: `url(${this.props.image})` }}>
                {this.props.children}
            </section>
        );
    }
}

export default ImageSection;