import memoize from 'memoizee';
import { apiUrl } from '../environment';
import { readFile, previewFile, submitFile, getSampleFile } from '../lib/fileHelper';

export const REQUEST_QUOTE = 'REQUEST_QUOTE';
function requestQuote() {
    return { type: REQUEST_QUOTE };
}
export const RECEIVE_QUOTE = 'RECEIVE_QUOTE';
function receiveQuote(quote) {
    return { type: RECEIVE_QUOTE, quote };
}

const makeRequest = (fileSubmissionId, stockMaterialId) => {
    return fetch(apiUrl + 'quotes', {
        method: 'POST',
        headers: { "Content-Type": "application/json" },
        mode: "cors",
        cache: "no-cache",
        body: JSON.stringify({ fileSubmissionId, stockMaterialId })
    }).then(res => res.json())
};
const makeRequestMemo = memoize(makeRequest, { promise: true, maxAge: 60000 });
export function fetchQuote(fileSubmissionId, stockMaterialId) {
    return dispatch => {
        if (fileSubmissionId === '' || stockMaterialId === '') {
            return Promise.resolve();
        }
        dispatch(requestQuote());
        makeRequestMemo(fileSubmissionId, stockMaterialId)
            .then(json => dispatch(receiveQuote(json)))
            .catch(console.error);
    }
}




export const REQUEST_FILE_SUBMISSION = 'REQUEST_FILE_SUBMISSION';
function requestFileSubmission() {
    return { type: REQUEST_FILE_SUBMISSION };
}

export const RECEIVE_FILE_SUBMISSION = 'RECEIVE_FILE_SUBMISSION';
function receiveFileSubmission(fileSubmission) {
    return { type: RECEIVE_FILE_SUBMISSION, ...fileSubmission };
}

export function selectFile(file) {
    return dispatch => {
        return Promise.all([
            readFile(file), previewFile(file)
        ]).then((results) => { //TODO: how to unwrap the array here?
            dispatch(requestFileSubmission());
            return submitFile(results[0], results[1]); // instead of here
        }).then(fileSubmission => dispatch(receiveFileSubmission(fileSubmission)))
            .catch(console.error);
    };
}

export function fetchSampleFileSubmission() {
    return dispatch => {
        dispatch(requestFileSubmission());
        return getSampleFile()
            .then(fileInfo => dispatch(receiveFileSubmission(fileInfo)))
            .catch(console.error);
    };
}



export const SET_STOCK_MATERIAL = 'SET_STOCK_MATERIAL';
export function setStockMaterial(id) {
    return { type: SET_STOCK_MATERIAL, id };
}

export const REQUEST_STOCK_MATERIALS = 'REQUEST_STOCK_MATERIALS';
function requestStockMaterials(materialTypeId) {
    return { type: REQUEST_STOCK_MATERIALS, materialTypeId };
}

export const RECEIVE_STOCK_MATERIALS = 'RECEIVE_STOCK_MATERIALS';
function receiveStockMaterials(data) {
    return { type: RECEIVE_STOCK_MATERIALS, stockMaterials: data };
}

export function fetchStockMaterials(materialTypeId) {
    return dispatch => {
        dispatch(requestStockMaterials(materialTypeId));

        return fetch(apiUrl + 'stockmaterials?materialTypeId=' + materialTypeId)
            .then(res => res.json())
            .then(json => dispatch(receiveStockMaterials(json)))
            .catch(console.error);
    };
}



export const SET_MATERIAL_TYPE = 'SET_MATERIAL_TYPE';
export function setMaterialType(id) {
    return dispatch => {
        dispatch({ type: SET_MATERIAL_TYPE, id });
        return dispatch(fetchStockMaterials(id));
    };
}

export const REQUEST_MATERIAL_TYPES = 'REQUEST_MATERIAL_TYPES';
function requestMaterialTypes() {
    return { type: REQUEST_MATERIAL_TYPES };
}

export const RECEIVE_MATERIAL_TYPES = 'RECEIVE_MATERIAL_TYPES';
function receiveMaterialTypes(data) {
    return { type: RECEIVE_MATERIAL_TYPES, materialTypes: data };
}

export function fetchMaterialTypes() {
    return dispatch => {
        dispatch(requestMaterialTypes());

        return fetch(apiUrl + 'materialtypes')
            .then(res => res.json())
            .then(json => dispatch(receiveMaterialTypes(json)))
            .catch(console.error);
    };
}


export const REQUEST_STATES = 'REQUEST_STATES';
function requestStates() {
    return { type: REQUEST_STATES };
}

export const RECEIVE_STATES = 'RECEIVE_STATES';
function receiveStates(data) {
    return { type: RECEIVE_STATES, states: data };
}

export function fetchStates() {
    return dispatch => {
        dispatch(requestStates());

        return fetch(apiUrl + 'states')
            .then(res => res.json())
            .then(json => dispatch(receiveStates(json)))
            .catch(console.error);
    };
}


export const SET_QUANTITY = 'SET_QUANTITY';
export function setQuantity(quantity) {
    return { type: SET_QUANTITY, quantity };
}

export const BACK_STEP = 'BACK_STEP';
export function backStep() {
    return { type: BACK_STEP };
}

export const NEXT_STEP = 'NEXT_STEP';
export function nextStep() {
    return { type: NEXT_STEP };
}

export const SET_ADDRESS_FIELD = 'SET_ADDRESS_FIELD';
export function setAddressField(key, val) {
    return { type: SET_ADDRESS_FIELD, key, val }
}


export const REQUEST_PAYMENT_TOKEN = 'REQUEST_PAYMENT_TOKEN';
function requestPaymentToken() {
    return { type: REQUEST_PAYMENT_TOKEN };
}

export const RECEIVE_PAYMENT_TOKEN = 'RECEIVE_PAYMENT_TOKEN';
function receivePaymentToken(token) {
    return { type: RECEIVE_PAYMENT_TOKEN, paymentToken: token.id };
}

export const REQUEST_PLACE_ORDER = 'REQUEST_PLACE_ORDER';
function requestPlaceOrder() {
    return { type: REQUEST_PLACE_ORDER };
}

export const RECEIVE_PLACE_ORDER = 'RECEIVE_PLACE_ORDER';
function receivePlaceOrder(orderDetails) {
    return { type: RECEIVE_PLACE_ORDER, orderDetails };
}

export function fetchPlaceOrder(orderRequest) {
    return dispatch => {
        dispatch(requestPaymentToken());

        return window.stripe.createToken()
            .then(({ token }) => {
                dispatch(receivePaymentToken(token));
                orderRequest.paymentToken = token.id;
            })
            .then(() => dispatch(requestPlaceOrder()))
            .then(() => {
                return fetch(apiUrl + 'orders', {
                    method: 'POST',
                    headers: { "Content-Type": "application/json" },
                    mode: "cors",
                    cache: "no-cache",
                    body: JSON.stringify(orderRequest)
                });
            })
            .then(res => res.json())
            .then(json => dispatch(receivePlaceOrder(json)))
            .catch(console.error);
    };
}

export const HIDE_ORDER_CONFIRMATION = 'HIDE_ORDER_CONFIRMATION';
export function hideOrderConfirmation() {
    return { type: HIDE_ORDER_CONFIRMATION };
}