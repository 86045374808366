import React, { Component } from 'react';

import FileSubmitterContainer from './FileSubmitterContainer';
import OrderOptionsContainer from './OrderOptionsContainer';
import QuantityOptionsContainer from './QuantityOptionsContainer';
import CheckoutFormContainer from './CheckoutFormContainer';
import NavigationButtons from './NavigationButtons';
import OrderConfirmationDialog from './OrderConfirmationDialog';
import { formatDollars } from '../lib/format';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';

class OrderWizard extends Component {
    handleFinish = () => {
        this.props.placeOrder({
            quoteId: this.props.quote.id,
            paymentToken: this.props.paymentToken,
            quantity: this.props.quantity,
            firstName: this.props.firstName,
            lastName: this.props.lastName,
            addressLine1: this.props.addressLine1,
            addressLine2: this.props.addressLine2,
            city: this.props.city,
            zipCode: this.props.zipCode,
            stateId: this.props.stateId,
            amount: this.props.subtotal
        });
    }
    render() {
        const total = formatDollars(this.props.subtotal);
        return (
            <div style={{textAlign: 'left', maxWidth: 555, margin: '0 auto'}}>
                <OrderConfirmationDialog 
                    open={this.props.displayOrderConfirmation}
                    onClose={this.props.hideOrderConfirmation}
                    />
                <Stepper activeStep={this.props.step} orientation="vertical" style={{padding: 0}}>
                    <Step key={0}>
                        <StepLabel>Upload Your Design</StepLabel>
                        <StepContent>
                            <FileSubmitterContainer />
                            <NavigationButtons 
                                onRight={this.props.nextStep}
                                />
                        </StepContent>
                    </Step>
                    <Step key={1}>
                        <StepLabel>Pick Your Material</StepLabel>
                        <StepContent>
                            <OrderOptionsContainer />
                            <NavigationButtons 
                                onLeft={this.props.backStep}
                                onRight={this.props.nextStep}
                                />
                        </StepContent>
                    </Step>
                    <Step key={2}>
                        <StepLabel>Decide How Many</StepLabel>
                        <StepContent>
                            <QuantityOptionsContainer />
                            <NavigationButtons 
                                onLeft={this.props.backStep}
                                onRight={this.props.nextStep}
                                />
                        </StepContent>
                    </Step>
                    <Step key={3}>
                        <StepLabel>Finalize Your Order</StepLabel>
                        <StepContent>
                            <CheckoutFormContainer />
                            <p>Estimated delivery: 7 days</p>
                            <NavigationButtons 
                                onLeft={this.props.backStep}
                                onRight={this.handleFinish}
                                rightText={`Place your Order (${total})`}
                                />
                        </StepContent>
                    </Step>
                </Stepper>
            </div>
        );
    }
}

export default OrderWizard;