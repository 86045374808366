import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

class Authenticator extends Component {
    render() {
        const login = (
            <Button
                color="inherit"
                onClick={()=> this.props.authenticate()}
            >
                Log In
            </Button>
        );
        const logout = (
            <Button
                color="inherit"
                onClick={()=> this.props.logout()}
            >
                Log Out
            </Button>
        );
        return this.props.token.length > 0 ? logout : login;
    }
}

export default Authenticator;