import React, { Component } from 'react'

import QuestionAnswer from './QuestionAnswer';
import ContactLink from '../ContactLink';

import './FaqPage.css';

export default class FaqPage extends Component {
  render() {
    return (
      <main>
          <section className="content faq">
            <h1>Frequently Asked Questions</h1>
            <p>Can't find an answer?  <ContactLink>Ask us!</ContactLink></p>

            <QuestionAnswer 
                question="How can I cancel or modify my order?">
                <p>
                    All of our parts are made to order, so once a part has been cut it can not be returned to stock.
                </p> 
                <p>
                    Cancelling an order will lead to a complete refund, excluding the cost of any work that has already been completed.
                </p>
                <p>
                    We will do our best to accomodate any modifications to an order that are requested.  Price adjustments will be based on
                    the scope of the changes and any work that has already been completed.
                </p>
            </QuestionAnswer>
            <QuestionAnswer 
                question="How long will it take for my order to arrive?">
                <p>
                    Typical orders will be reviewed, manufactured, and shipped in under 4 business days.  High volume orders and
                    exotic or out of stock materials may require additional time for fulfillment.
                </p>
                <p>
                    All orders will be validated by a member of our team to ensure that they are production ready.  In the event
                    that we find any issues with your design you will be contacted directly via the contact information you provided
                    when placing your order.
                </p>
                <p>
                    A tracking number will be provided via email once your order has shipped.
                </p>
            </QuestionAnswer>
            <QuestionAnswer 
                question="What is the status of my order?">
                <p>
                    You will be notified via email as your order moves through the following steps:
                </p>
                <ol>
                    <li>Order Received - We have received your order.</li>
                    <li>Design Review - Your design is being reviewed by a member of our team.</li>
                    <li>Production - Your design has been released to our shop floor for production!</li>
                    <li>Shipped - Your parts are on their way!</li>
                </ol>
                <p>
                    If you have additional questions about the status of your order you can always <ContactLink>chat with us</ContactLink>.
                </p>
            </QuestionAnswer>
            <QuestionAnswer 
                question="What payment methods are accepted? Is it secure?">
                <p>
                    You can rest easy knowing that your payment details are sent directly to <a href="https://stripe.com">Stripe</a> using SSL encryption.  We never even
                    see your credit card details, the entire transaction is handled directly by <a href="https://stripe.com">Stripe</a>.
                </p>
            </QuestionAnswer>
          </section>
      </main>
    )
  }
}
