import React, { Component } from 'react';

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

class QuantitySelector extends Component {
    handleChange = event => {
        const quantity = event.target.value;
        this.props.onSelected(quantity);
    };
    render() {
        return (
            <div>
                <FormControl style={{width: "100%"}}>
                    <TextField 
                        id="quantity"
                        label="Quantity"
                        value={this.props.quantity}
                        onChange={this.handleChange}
                        margin="normal"
                        />
                </FormControl>
            </div>
        );
    }
}

export default QuantitySelector;