import React, { Component } from 'react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';

import Title from './Title';
import AuthenticatorContainer from './AuthenticatorContainer';

import './Header.css';

class Header extends Component {
    constructor() {
        super();
        this.state = { scrolledUp: false }
    }
    componentDidMount() {
        window.onscroll = () => {
            if (window.scrollY > 10) {
                this.setState({ scrolledUp: true });
            } else if (this.state.showHeader) {
                this.setState({ scrolledUp: false });
            }
        }
    }
    hideHeader = () => {
        // Forgive me, for I have sinned.
        return this.state.scrolledUp === false && window.location.pathname === '/';
    }
    render() {
        return (
            <AppBar position="sticky" color="primary" className={this.hideHeader() ? 'hiddenToolbar' : ''}>
                <Toolbar>
                    <Title />
                    <nav>
                        <Button 
                            component={Link} 
                            to="/blog/laser-cut-fall-crafting-ideas" 
                            color="inherit">
                            Blog
                        </Button>
                        <AuthenticatorContainer />
                    </nav>
                </Toolbar>
            </AppBar>
        );
    }
}

export default Header;