
import { connect } from "react-redux";
import FileSubmitter from "./FileSubmitter";
import { selectFile, fetchSampleFileSubmission } from "../actions/order";

const mapStateToProps = function (state) {
    return { ...state.order };
}

const mapDispatchToProps = dispatch => ({
    onFileSelected: base64Data => dispatch(selectFile(base64Data)),
    loadSample: () => dispatch(fetchSampleFileSubmission())
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileSubmitter);