import React, {Component} from 'react';
import {injectStripe} from 'react-stripe-elements';
import AddressInputContainer from './AddressInputContainer';
import StripeCardInput from './StripeCardInput';


class CheckoutForm extends Component {
    handleSubmit = (event) => {
        event.preventDefault();
        this.props.stripe.createToken({ name: 'Jenny Rosen' }).then(({ token }) => {
            console.log('Received Stripe token:', token);
        });
    };
    render() {
        return (
            <form onSubmit={this.handleSubmit} >
                <AddressInputContainer />
                <StripeCardInput />
            </form>
        );
    }
}

export default injectStripe(CheckoutForm);