import { connect } from "react-redux";
import { setAddressField, fetchStates } from "../actions/order";
import AddressInput from "./AddressInput";

const mapStateToProps = function (state) {
    return { ...state.order };
}

const mapDispatchToProps = dispatch => ({
    setAddressField: (key, val) => dispatch(setAddressField(key, val)),
    fetchStates: () => dispatch(fetchStates()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddressInput);

