import { REQUEST_SUBSCRIBE, RECEIVE_SUBSCRIBE, SET_EMAIL, DISMISS_THANKS } from '../actions/subscribe';

const defaultState = {
    isRequesting: false,
    email: '',
    thanksVisible: false
};

const mailingList = (state = defaultState, action) => {
    switch (action.type) {
        case SET_EMAIL:
            return Object.assign({}, state, { email: action.email });
        case REQUEST_SUBSCRIBE:
            return Object.assign({}, state, { isRequesting: true });
        case RECEIVE_SUBSCRIBE:
            return Object.assign({}, { isRequesting: false, email: '', thanksVisible: true });
        case DISMISS_THANKS:
            return Object.assign({}, state, { thanksVisible: false });
        default:
            return state;
    }
}

export default mailingList;