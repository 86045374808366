import React, { Component } from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

class StateSelector extends Component {
    handleChange = event => {
        const stateId = event.target.value;
        this.props.onStateSelected(stateId);
    }
    componentDidMount() {
        this.props.fetchStates();
    }
    render() {
        const stateOptions = this.props.states.map(m => <MenuItem value={m.id} key={m.id}>{m.name}</MenuItem>);
        return (
                <FormControl style={{width: "calc(33% - 10px)", marginRight: 10}}>
                    <InputLabel htmlFor="state">State</InputLabel>
                    <Select 
                        value={this.props.stateId || ''}
                        onChange={this.handleChange}
                        className="state"
                        inputProps={{ name: 'state', id: 'state' }}
                        >
                        {stateOptions}
                    </Select>
                </FormControl>
        );
    }
}

export default StateSelector;