import React, { Component } from 'react';

import HeroSection from './HeroSection';
import SubscribeContainer from './SubscribeContainer';
import ImageSection from "../ImageSection";

import craftingImage from './crafting.jpg';
import ValuePropositions from './ValuePropositions';
import HowItWorksSection from './HowItWorksSection';

class HomePage extends Component {
    render() {
        return (
            <main id="main">
                <HeroSection /> 
                <HowItWorksSection />
                <ValuePropositions />
                <ImageSection image={craftingImage}>
                    <SubscribeContainer />
                </ImageSection>
            </main>
        );
    }
}

export default HomePage;