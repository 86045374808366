import { connect } from "react-redux";
import QuantityOptions from "./QuantityOptions";
import { 
    setQuantity,
    fetchQuote
} from "../actions/order";
import { getSubtotal } from "../selectors";

const mapStateToProps = function (state) {
    return { 
        subtotal: getSubtotal(state),
        ...state.order 
    };
}

const mapDispatchToProps = dispatch => ({
    onQuantityChanged: qty => dispatch(setQuantity(qty)),
    fetchQuote: (fileSubmission, stockMaterialId) => dispatch(fetchQuote(fileSubmission, stockMaterialId)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuantityOptions);