import React, { Component } from 'react';
import './App.css';

import SmoothScroll from 'smooth-scroll';
import ScrollToTop from './ScrollToTop';

import Header from './Header';
import HomePage from './home/HomePage'
import PostPageContainer from './blog/PostPageContainer'
import FaqPage from './faq/FaqPage';
import LegalPage from './legal/LegalPage';
import PrivacyPage from './legal/PrivacyPage';
import TermsPage from './legal/TermsPage';
import SweepstakesDetailsPage from './legal/SweepstakesDetailsPage';
import SweepstakesWinnersPage from './legal/SweepstakesWinnersPage';
import PageNotFound from './PageNotFound';
import Footer from './Footer';

import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';

class App extends Component {
  componentWillMount() {
    new SmoothScroll('a[href*="#"]', {header: 'header'});
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div className="app">
          <Router>
            <ScrollToTop>
              <Header />
              <Switch>
                <Route exact path="/" component={HomePage} />
                <Route path="/blog/:slug" component={PostPageContainer} />
                <Route path="/faq" component={FaqPage} />
                <Route path="/legal" component={LegalPage} />
                <Route path="/privacy-policy" component={PrivacyPage} />
                <Route path="/terms-and-conditions" component={TermsPage} />
                <Route path="/sweepstakes-details" component={SweepstakesDetailsPage} />
                <Route path="/sweepstakes-winners" component={SweepstakesWinnersPage} />
                <Route component={PageNotFound} />
              </Switch>
              <Footer />
            </ScrollToTop>
          </Router>
      </div>
    );
  }
}

export default App;
