
import React, { Component } from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

class StockMaterialSelector extends Component {
    handleThicknessChange = event => {
        const stockMaterialId = event.target.value;
        this.props.onStockMaterialSelected(stockMaterialId);
    }

    render() {
        const styleFullWidth = { width: '100%' };
        const thicknessOptions = this.props.stockMaterials.map(m => <MenuItem value={m.id} key={m.id}>{m.thicknessInInches}"</MenuItem>);
        return (
            <FormControl style={styleFullWidth} margin="normal">
                <InputLabel htmlFor="thickness">Thickness</InputLabel>
                <Select 
                    value={this.props.stockMaterialId}
                    onChange={this.handleThicknessChange}
                    inputProps={{ name: 'thickness', id: 'thickness' }}
                    className="thickness"
                    >
                    {thicknessOptions}
                </Select>
            </FormControl>
        );
    }
}

export default StockMaterialSelector;