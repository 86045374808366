import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';

class UseSampleFileButton extends Component {
    render() {
        return (
            <Button id="use-sample-file" size="small" aria-label="Use Sample" onClick={this.props.onClick}>
                <AddCircleIcon style={{marginRight: 3}}/>
                Use Sample File
            </Button>
        );
    }
}

export default UseSampleFileButton;