import React, { Component } from 'react'

export default class PageNotFound extends Component {
  render() {
    return (
      <main>
          <section className="content text-page">
            <h1>Yikes!</h1>
            <p>I can't seem to find the page you're looking for.</p>
          </section>
      </main>
    )
  }
}
