import {
    SHOW_LOCK,
    RECEIVE_PROFILE,
    RECEIVE_TOKEN
} from '../actions/authentication';

const defaultState = {
    token: '',
    profile: {}
};

const authentication = (state = defaultState, action) => {
    switch (action.type) {
        case SHOW_LOCK:
            return state;
        case RECEIVE_TOKEN:
            return Object.assign({}, state, { token: action.token });
        case RECEIVE_PROFILE:
            return Object.assign({}, state, { profile: action.profile });
        default:
            return state;
    }
}

export default authentication;