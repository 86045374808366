import grey from '@material-ui/core/colors/grey';

const theme = {
  typography: {
    fontFamily: [
      'Montserrat',
      'sans-serif'
    ],
  },
  palette: {
    primary: {
      light: "#08346F",
      main: "#08346F"
    },
    secondary: grey,
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#08346F"
      }
    }
  }
};

export default theme;