import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import App from './App';
import theme from './theme';
import {stripeKey} from './environment';

import { Provider } from 'react-redux';
import {StripeProvider} from 'react-stripe-elements';
import store from './store';

const muiTheme = createMuiTheme(theme);

function ThemedApp() {
  return (
    <Provider store={store}>
      <StripeProvider apiKey={stripeKey}>
        <MuiThemeProvider theme={muiTheme}>
          <App />
        </MuiThemeProvider>
      </StripeProvider>
    </Provider>
  );
}

export default ThemedApp;
