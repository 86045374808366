import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

import './NavigationButtons.css';

class NavigationButtons extends Component {
    render() {
        const props = this.props;
        return (
            <div className="navigation-buttons">
                <Button
                    disabled={props.onLeft === undefined}
                    style={{marginRight: '1em'}}
                    className="left"
                    onClick={props.onLeft}>
                    Back
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className="right"
                    onClick={props.onRight}>
                    {props.rightText || "Next" }
                </Button>
            </div>
        );
    }
}

export default NavigationButtons;
