import React, { Component } from 'react';

import MaterialTypeSelector from './MaterialTypeSelector';
import ThicknessSelector from './ThicknessSelector';

class OrderOptions extends Component {
    render() {
        return (
            <div>
                <MaterialTypeSelector 
                    onMaterialTypeSelected={this.props.onMaterialTypeChanged}
                    fetchMaterialTypes={this.props.fetchMaterialTypes}
                    materialTypeId={this.props.materialTypeId}
                    materialTypes={this.props.materialTypes}
                    />
                <ThicknessSelector 
                    onStockMaterialSelected={this.props.onStockMaterialChanged} 
                    stockMaterialId={this.props.stockMaterialId}
                    stockMaterials={this.props.stockMaterials}
                    />
            </div>
        );
    }
}

export default OrderOptions;