import { apiUrl } from '../environment';
import { parseString, toSVG } from 'dxf';

export function readFile(file) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = function(){
            var dataURL = reader.result.replace('data:;', `data:${file.extension};`);
            resolve(dataURL);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}

export function submitFile(fileData, filePreview) {
    return new Promise((resolve) => {
        fetch(apiUrl + 'files', {
            method: 'POST',
            headers: { "Content-Type": "application/json;" },
            mode: "cors",
            cache: "no-cache",
            body: JSON.stringify({fileData, filePreview})
        })
        .then(res => res.json())
        .then(res => {
            resolve({ 
                id: res.id, 
                width: formatDistance(res.widthIn),
                height: formatDistance(res.heightIn),
                cutLength: formatDistance(res.cutLengthIn),
                preview: res.previewImage
            });
        });
    });
}

export function getSampleFile() {
    return fetch(apiUrl + 'files/', {
        method: 'GET',
        headers: { "Content-Type": "application/json" },
        mode: "cors",
        cache: "no-cache",
    }).then(res => res.json())
    .then(res => {
        return {
            id: res.id,
            width: formatDistance(res.widthIn),
            height: formatDistance(res.heightIn),
            cutLength: formatDistance(res.cutLengthIn),
            preview: res.previewImage
        };
    });
}

function formatDistance(distance) {
    return parseFloat(Math.round(distance * 100) / 100).toFixed(2);
}

export function previewFile(file) {
    return new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = function(){
            const dxfContents = reader.result;
            const parsed = parseString(dxfContents);
            const svg = toSVG(parsed).replace('stroke-width="0.1%"', 'stroke-width="1%"');
            resolve(svg);
        };
        reader.onerror = reject;
        reader.readAsBinaryString(file);
    });
}