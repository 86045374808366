import React, {Component, Fragment} from 'react';

import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';
import Jump from 'react-reveal/Jump';

import arduinoUnoSrc from './arduino-uno.jpg';

class SubscribeForm extends Component {
    subscribe = () => {
        this.props.subscribe(this.props.email);
    }
    render() {
        return (
            <Fragment>
                    <h2 className="react-reveal-inline">Win a <Jump count={2} duration={1500}>Free</Jump> Arduino!</h2>
                    <div className="side-by-side-img">
                        <div>
                            <p>
                                Sign up for our newsletter and get a chance to win a free Arduino Uno.<br />
                                <Link to="sweepstakes-details" className="contest-rules">(see official rules)</Link>
                            </p>
                        </div>
                        <div>
                            <img src={arduinoUnoSrc} alt="Arduino Uno Microcontroller" />
                        </div>
                    </div>
                    <form>
                        <FormControl margin="normal" style={{marginTop: ".8rem"}}>
                            <TextField 
                                id="email"
                                placeholder="enter your email address"
                                value={this.props.email}
                                onChange={this.props.setEmail}
                                style={{marginright: "1rem", width: "13em" }}
                                />
                        </FormControl>
                        <FormControl margin="normal">
                            <Button 
                                variant="contained" 
                                onClick={this.subscribe}
                                style={{marginLeft: '1em'}}
                                id="subscribe"
                                color="primary">
                                Subscribe
                            </Button>
                        </FormControl>
                    </form>
            </Fragment>
        );
    }
}

export default SubscribeForm;