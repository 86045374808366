import {
    REQUEST_BLOG_POST,
    RECEIVE_BLOG_POST,
} from '../actions/blog';

const defaultState = {
    isLoading: false,
    blog: null,
};

const blogPost = (state = defaultState, action) => {
    switch (action.type) {
        case REQUEST_BLOG_POST:
            return { isLoading: true };
        case RECEIVE_BLOG_POST:
            return Object.assign({}, state, { isLoading: false, ...action.blog });
        default:
            return state;
    }
}

export default blogPost;