import React, { Component } from 'react';

import CallToAction from './CallToAction';

import './HeroSection.css';

class HeroSection extends Component {
    render() {
        return (
            <section className="hero">
                <div>
                    <div className="tagline">
                        <h1>
                            Bring ideas to life
                        </h1>
                        <span>WITH PENUMBRA LASER CUTTING</span>
                    </div>
                    <div className="hero-content">
                        <CallToAction />
                    </div>
                </div>
            </section>
        );
    }
}

export default HeroSection;