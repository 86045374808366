import lock from './lock';

export const SHOW_LOCK= 'SHOW_LOCK';
function showLock() {
    return { type: SHOW_LOCK };
}

export const RECEIVE_TOKEN = 'RECEIVE_TOKEN';
export function receiveToken(token) {
    return { type: RECEIVE_TOKEN, token };
}

export const RECEIVE_PROFILE = 'RECEIVE_PROFILE';
export function receiveProfile(profile) {
    return { type: RECEIVE_PROFILE, profile };
}

export function authenticate() {
    lock.show();
    return showLock();
}

export const LOGOUT = 'LOGOUT';
export function logout() {
    lock.logout({returnTo: window.location.href});
    return { type: LOGOUT };
}